  import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./Redux/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import "./utils/i18n";

const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<Spinner />} persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <LazyApp />
        </Suspense>
      </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorker.unregister();
