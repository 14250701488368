const INITIAL_STATE = {
  userToken: null,
  user: {},
  role: "guest",
  isLoading: false,
  errors: {},
};

export const login = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "LOGIN": {
      let userRole = action.user.roles
        ? action.user.roles[0]
          ? action.user.roles[0].name
          : "pharmacy-user"
        : "pharmacy-user";
      return {
        ...state,
        isLoading: false,
        user: action.user,
        role: userRole,
        userToken: action.token,
      };
    }
    case "LOGIN_ERROR": {
      return { ...state, errors: action.errors, isLoading: false };
    }
    case "LOGIN_START": {
      return { ...state, isLoading: true, errors: {} };
    }
    case "LOGOUT": {
      return INITIAL_STATE;
    }
    case "CHANGE_ROLE": {
      return { ...state, role: action.userRole };
    }
    default: {
      return state;
    }
  }
};
