import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";

const rootReducer = combineReducers({
  auth,
  layout,
});

export default rootReducer;
