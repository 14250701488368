import React from "react";
import * as Icon from "react-feather";
import { getOrSetLng } from "./functions";

export const defaultLng = "fr";
export const allLng = ["fr", "it", "de"];
let lng = getOrSetLng();
export const horizontalMenuConfig = [
  {
    id: "home",
    title: lng === "fr" ? "Accueil" : "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin"],
    navLink: "/admin/dashboard",
  },
  {
    id: "entitiepage",
    title: lng === "fr" ? "Entités" : lng === "de" ? "Entitäten" : "Entità",
    icon: <Icon.List size={20} />,
    permissions: ["admin"],
    type: "dropdown",
    navLink: "/entities",
    children: [
      {
        id: "activitiespage",
        title:
          lng === "fr"
            ? "Activités"
            : lng === "de"
            ? "Aktivitäten"
            : "Attività",
        icon: <Icon.ShoppingBag size={20} />,
        type: "item",
        navLink: "/entities/activities",
        permissions: ["admin"],
      },
      {
        id: "supplier",
        title:
          lng === "fr"
            ? "Fournisseurs"
            : lng === "de"
            ? "Lieferanten"
            : "Fornitori",
        icon: <Icon.Package size={20} />,
        type: "item",
        navLink: "/entities/supplier",
        permissions: ["admin"],
      },
      {
        id: "product",
        title:
          lng === "fr" ? "Produits" : lng === "de" ? "Produkte" : "Prodotti",
        icon: <Icon.Watch size={20} />,
        type: "item",
        navLink: "/entities/product",
        permissions: ["admin"],
      },
      {
        id: "materiels",
        title:
          lng === "fr"
            ? "Matériels"
            : lng === "de"
            ? "Materialien"
            : "Materiali",
        icon: <Icon.Clipboard size={20} />,
        type: "item",
        navLink: "/entities/materials",
        permissions: ["admin"],
      },
      {
        id: "admin",
        title: lng === "fr" ? "Admin" : lng === "de" ? "Admin" : "Admin",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin"],
        navLink: "/admin",
      },
      {
        id: "pharmacies",
        title:
          lng === "fr" ? "Pharmacies" : lng === "de" ? "Apotheken" : "Farmacie",
        type: "item",
        icon: <Icon.PlusSquare size={20} />,
        permissions: ["admin"],
        navLink: "/pharmacies",
      },
    ],
  },
  {
    id: "periodespage",
    title: lng === "fr" ? "Planificateur" : lng === "de" ? "Planer" : "Planner",
    icon: <Icon.Calendar size={20} />,
    type: "item",
    navLink: "/planner",
    permissions: ["admin"],
  },
  {
    id: "selectionpage",
    title:
      lng === "fr"
        ? "Vue d’ensemble"
        : lng === "de"
        ? "Überblick"
        : "Panoramica",
    type: "item",
    icon: <Icon.Eye size={20} />,
    permissions: ["admin"],
    navLink: "/admin/selection",
  },
  {
    id: "questionpage",
    title: lng === "fr" ? "Question" : lng === "de" ? "Frage" : "Domanda",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    permissions: ["admin"],
    navLink: "/question",
  },
  {
    id: "deliverycenter",
    title:
      lng === "fr"
        ? "Centre d’envoi"
        : lng === "de"
        ? "Versandzentrum"
        : "Centro di spedizione",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["admin"],
    navLink: "/admin/deliverycenter",
  },
  {
    id: "export_image",
    title:
      lng === "fr"
        ? "Exportation images activité"
        : lng === "de"
          ? "Export von Bildern Aktivität"
          : "Esportazione delle immagini delle attività",
    type: "item",
    icon: <Icon.Download size={20} />,
    permissions: ["admin"],
    navLink: "/admin/exportimage",
  },
  //Pharmacy
  /*{
      id: "home",
      title: lng==='fr'?'Accueil': lng==='de'?'Zuhause':'Accoglienza',
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["pharmacy", "pharmacy-user"],
      navLink: "/pharmacy/dashboard",
  },*/
  {
    id: "activitiespage",
    title: lng === "fr" ? "Accueil" : "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["pharmacy", "pharmacy-user"],
    navLink: "/pharmacy/activities",
  },
  /*
  {
      id: "selectionpage",
      title: lng==='fr'?'Sélection': lng==='de'?'Auswahl':'Selezione',
      type: "item",
      icon: <Icon.CheckSquare size={20} />,
      permissions: ["pharmacy"],
      navLink: "/pharmacy/selection",
  },
  {
      id: "questionpage",
      title: lng==='fr'?'Question': lng==='de'?'Frage':'Domanda',
      type: "item",
      icon: <Icon.MessageSquare size={20} />,
      permissions: ["pharmacy"],
      navLink: "/pharmacy/question",
  },
  */
  {
    id: "deliverycenter",
    title: lng === "fr" ? "Colis" : lng === "de" ? "Paket" : "Pacco",
    type: "item",
    icon: <Icon.Package size={20} />,
    permissions: ["pharmacy"],
    navLink: "/pharmacy/deliverycenter",
  },
];
export const themeConfig = {
  layout: "horizontal", // options[String]: "vertical"(default), "horizontal"
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
  footerType: "static", // options[String]: static(default) / sticky / hidden
  disableCustomizer: false, // options[Boolean]: true, false(default)
  hideScrollToTop: false, // options[Boolean]: true, false(default)
  menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr", // options[String] : ltr / rtl
};

export const verticalMenuConfig = [
  {
    id: "home",
    title: lng === "fr" ? "Accueil" : "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin"],
    navLink: "/admin/dashboard",
  },
  {
    id: "entitiepage",
    title: lng === "fr" ? "Entités" : lng === "de" ? "Entitäten" : "Entità",
    icon: <Icon.List size={20} />,
    permissions: ["admin"],
    type: "collapse",
    navLink: "/entities",
    children: [
      {
        id: "activitiespage",
        title:
          lng === "fr"
            ? "Activités"
            : lng === "de"
            ? "Aktivitäten"
            : "Attività",
        icon: <Icon.ShoppingBag size={20} />,
        type: "item",
        navLink: "/entities/activities",
        permissions: ["admin"],
      },
      {
        id: "supplier",
        title:
          lng === "fr"
            ? "Fournisseurs"
            : lng === "de"
            ? "Lieferanten"
            : "Fornitori",
        icon: <Icon.Package size={20} />,
        type: "item",
        navLink: "/entities/supplier",
        permissions: ["admin"],
      },
      {
        id: "product",
        title:
          lng === "fr" ? "Produits" : lng === "de" ? "Produkte" : "Prodotti",
        icon: <Icon.Watch size={20} />,
        type: "item",
        navLink: "/entities/product",
        permissions: ["admin"],
      },
      {
        id: "materiels",
        title:
          lng === "fr"
            ? "Matériels"
            : lng === "de"
            ? "Materialien"
            : "Materiali",
        icon: <Icon.Clipboard size={20} />,
        type: "item",
        navLink: "/entities/materials",
        permissions: ["admin"],
      },
      {
        id: "admin",
        title: lng === "fr" ? "Admin" : lng === "de" ? "Admin" : "Admin",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin"],
        navLink: "/admin",
      },
      {
        id: "pharmacies",
        title:
          lng === "fr" ? "Pharmacies" : lng === "de" ? "Apotheken" : "Farmacie",
        type: "item",
        icon: <Icon.PlusSquare size={20} />,
        permissions: ["admin"],
        navLink: "/pharmacies",
      },
    ],
  },
  {
    id: "periodespage",
    title: lng === "fr" ? "Planificateur" : lng === "de" ? "Planer" : "Planner",
    icon: <Icon.Calendar size={20} />,
    type: "item",
    navLink: "/planner",
    permissions: ["admin"],
  },
  {
    id: "selectionpage",
    title:
      lng === "fr"
        ? "Vue d’ensemble"
        : lng === "de"
        ? "Überblick"
        : "Panoramica",
    type: "item",
    icon: <Icon.Eye size={20} />,
    permissions: ["admin"],
    navLink: "/admin/selection",
  },
  {
    id: "questionpage",
    title: lng === "fr" ? "Question" : lng === "de" ? "Frage" : "Domanda",
    type: "item",
    icon: <Icon.MessageSquare size={20} />,
    permissions: ["admin"],
    navLink: "/question",
  },
  {
    id: "deliverycenter",
    title:
      lng === "fr"
        ? "Centre d’envoi"
        : lng === "de"
        ? "Versandzentrum"
        : "Centro di spedizione",
    type: "item",
    icon: <Icon.Send size={20} />,
    permissions: ["admin"],
    navLink: "/admin/deliverycenter",
  },
  //Pharmacy
  /*{
      id: "home",
      title: lng==='fr'?'Accueil': lng==='de'?'Zuhause':'Accoglienza',
      type: "item",
      icon: <Icon.Home size={20} />,
      permissions: ["pharmacy", "pharmacy-user"],
      navLink: "/pharmacy/dashboard",
  },*/
  {
    id: "activitiespage",
    title: lng === "fr" ? "Accueil" : "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["pharmacy", "pharmacy-user"],
    navLink: "/pharmacy/activities",
  },
  /*
  {
      id: "selectionpage",
      title: lng==='fr'?'Sélection': lng==='de'?'Auswahl':'Selezione',
      type: "item",
      icon: <Icon.CheckSquare size={20} />,
      permissions: ["pharmacy"],
      navLink: "/pharmacy/selection",
  },
  {
      id: "questionpage",
      title: lng==='fr'?'Question': lng==='de'?'Frage':'Domanda',
      type: "item",
      icon: <Icon.MessageSquare size={20} />,
      permissions: ["pharmacy"],
      navLink: "/pharmacy/question",
  },
   */
  /* not display the delivery center for pharmacy phone
  {
      id: "deliverycenter",
      title: lng==='fr'?'Colis': lng==='de'?'Paket':'Pacco',
      type: "item",
      icon: <Icon.Package size={20} />,
      permissions: ["pharmacy"],
      navLink: "/pharmacy/deliverycenter",
  },*/
];
