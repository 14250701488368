import { themeConfig } from "../../../utils/configs";

const MenuSidebar = (state = themeConfig, action) => {
  switch (action.type) {
    case "CHANGE_MODE":
      return { ...state, theme: action.mode };
    case "COLLAPSE_SIDEBAR":
      return { ...state, sidebarCollapsed: action.value };
    default:
      return state;
  }
};

export default MenuSidebar;
