import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createEncryptor from "redux-persist-transform-encrypt";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const encryptor = createEncryptor({
  secretKey: "my-super-secret-key",
  onError: function (error) {
    // Handle the error.
  },
});

const middlewares = [thunk, createDebounce()];

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

let persistor = persistStore(store);

export { store, persistor };
