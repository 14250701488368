import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import {reactI18nextModule} from "react-i18next";
import translationEN from "../i18n/en/en.json";
import translationDE from "../i18n/de/de.json";
import translationFR from "../i18n/fr/fr.json";
import translationIT from "../i18n/it/it.json";
import {getOrSetLng} from "./functions";
import {defaultLng} from "./configs";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getOrSetLng(),
    fallbackLng: defaultLng, // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
