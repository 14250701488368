import { allLng, defaultLng } from "./configs";

export function validateEmail(email) {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getOrSetLng() {
  let localLng = localStorage.getItem("lng");
  if (localLng) {
    if (!allLng.includes(localLng)) {
      localStorage.setItem("lng", defaultLng);
      localLng = defaultLng;
    }
  } else {
    localStorage.setItem("lng", defaultLng);
    localLng = defaultLng;
  }
  return localLng;
}

export function lngToString(lng) {
  let stringToReturn = "";
  switch (lng) {
    default:
    case "fr": {
      stringToReturn = "Français";
      break;
    }
    case "it": {
      stringToReturn = "Italien";
      break;
    }
    case "de": {
      stringToReturn = "Allemand";
      break;
    }
  }
  return stringToReturn;
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function filterDataByStatus(periods, filterStatus) {
  let toReturn = [];
  if (filterStatus) {
    if (filterStatus === null) {
      toReturn = periods;
    } else {
      periods.map((Period) => {
        let newPeriod = JSON.parse(JSON.stringify(Period));
        let newPeriodActivities = [];
        Period.activities.map((Activity) => {
          if (Activity.status === null && filterStatus === "selection") {
            return newPeriodActivities.push(Activity);
          }
          if (Activity.status === filterStatus) {
            return newPeriodActivities.push(Activity);
          } else return false;
        });
        newPeriod.activities = newPeriodActivities;
        return toReturn.push(newPeriod);
      });
    }
  } else {
    toReturn = periods;
  }
  return toReturn;
}

export function periodsFilter(periods, selectedYear, selectedStatus) {
  let periodsToReturn = filterDataByStatus(periods, selectedStatus);
  periodsToReturn = periodsToReturn.filter((Period) => {
    let periodStart = Period.start.substring(0, 4);
    return periodStart === selectedYear;
  });
  return periodsToReturn;
}

export function getNbrSelectedProducts(products) {
  let toReturn = 0;
  products.map((Product) => {
    return (toReturn = Product.selected_by_pharmacy ? toReturn + 1 : toReturn);
  });
  return toReturn;
}

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}
